.card {
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    
    h2 {
      margin-bottom: 0;
    }
    
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: auto;
      min-height: 49px;
      padding: 24px;
      gap: 8px;
    }
  }

  .card-body {
    .table-search {
      label {
        white-space: nowrap;
      }
      
      // .btn-toolbar {
      //   .btn-primary {
      //     margin-left: 8px;
      //   }

      //   .btn-link {
      //     margin-right: 8px;
      //   }
      // }

      @media (max-width: 1199px) {
        .form-group {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 1rem;

          .form-label {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}