/**
 * = Paginations
 */
.pagination {
  margin-top: 0;

  .page-item {
    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-msb-gray-6;
      text-decoration: none;
      padding: 0 4px;
      border: 1px solid $color-gray-5;
      border-radius: 4px;
      line-height: 1;
      height: 32px;
      min-width: 32px;

      &:focus {
        background: $white;
        border: 1px solid $color-navient-dark-blue-6;
        box-shadow: inset 0 0 0 1px $color-navient-dark-blue-6;
        color: $color-navient-dark-blue-6;
        outline-color: transparent;
        outline-width: 2px;
        outline-style: dotted;
      }

      &:hover {
        background: $white;
        color: $color-navient-dark-blue-5;
        text-decoration: none;
        border: 1px solid $color-navient-dark-blue-5;
      }

      &:focus:hover {
        border: 1px solid $color-navient-dark-blue-6;
      }

      &:not(:disabled):active,
      &:not(:disabled).active {
        background-color: $white;
        outline: 0;
        box-shadow: none;
        border: 1px solid $color-navient-dark-blue-6;
        color: $color-navient-dark-blue-6;
      }

      &:focus:active {
        box-shadow: none;
      }
    }

    &.active {
      .page-link {
        background-color: $white;
        color: $color-navient-dark-blue-6;
        font-weight: $font-weight-bold;
        border-color: $color-navient-dark-blue-6;
        border-radius: 4px;
      }
    }

    &.disabled {
      .page-link {
        background-color: $color-gray-3;
        color: $color-msb-gray-3;
        cursor: not-allowed;
      }
    }

    &:last-child,
    &:first-child {
      .page-link {
        border-radius: 4px;
      }
    }

    &:last-child {
      .page-link {
        margin-right: 0;
      }
    }
  }

  &.react-bootstrap-table-page-btns-ul {
    gap: 6px;
    margin-bottom: 0;
    
    @media (max-width: 575px) {
      flex-wrap: wrap;
    }

    li[title="Previous page"],
    li[title="Next page"],
    li[title="First page"],
    li[title="Last page"] {
      .page-link {
        font-size: 0.625rem;
        font-weight: 900;
        padding: 0 8px;
        -webkit-text-stroke: .02em $color-msb-gray-6;

        &:focus {
          -webkit-text-stroke: .02em $color-navient-dark-blue-6;
        }
  
        &:hover {
          -webkit-text-stroke: .02em $color-navient-dark-blue-5;
        }
  
        &:focus:hover {
          -webkit-text-stroke: .02em $color-navient-dark-blue-6;
        }
      }

      &.disabled {
        .page-link {
          -webkit-text-stroke: .02em $color-msb-gray-3;
          cursor: not-allowed;
        }
      }

    }
  }
}

.react-bootstrap-table-pagination {
  display: flex;
  align-items: center;
  grid-row-gap: 6px;
  margin-top: 16px;
  // background-color: pink;

  @media (max-width: 996px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    .col-md-6 {
      width: 100%;
    }
  }

  &.row > * {
    display: flex;
    align-items: center;
  }

  .react-bootstrap-table-pagination-list {
    justify-content: flex-end;

    @media (max-width: 996px) {
      justify-content: flex-start;
    }

  }

  .react-bs-table-sizePerPage-dropdown.dropdown {
    button {
      background-color: $white;
      color: $color-msb-gray-6;
      border: 1px solid $color-gray-5;
      height: 32px;
      padding: 0 12px;
      margin-right: 8px;

      &:focus {
        border: 1px solid $color-navient-dark-blue-6;
        box-shadow: inset 0 0 0 1px $color-navient-dark-blue-6;
        outline-color: transparent;
        outline-width: 2px;
        outline-style: dotted;
      }

      &:hover,
      &:active {
        text-decoration: none;
        color: $color-navient-dark-blue-6;
        background-color: $white;
        border-color: $color-gray-5;
        box-shadow: none;
      }

      span {
        display: none;
      }

      &::after {
        content: "\f078";
        color: $color-msb-gray-6;
        font-family: "Font Awesome 5 Free";
        display: inline-block;
        font-size: 0.625rem;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 900;
        line-height: 0;
        margin-left: 4px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        border: none;
        position: relative;
        top: 2px;
      }
    }
  }
}

.react-bootstrap-table-pagination .dropdown {
  button {
    background-color: $white;
    color: $color-msb-gray-6;
    border: 1px solid $color-gray-5;
    height: 32px;
    padding: 0 12px !important;
    margin-right: 8px;

    &:focus {
      border: 1px solid $color-navient-dark-blue-6;
      box-shadow: inset 0 0 0 1px $color-navient-dark-blue-6;
      outline-color: transparent;
      outline-width: 2px;
      outline-style: dotted;
    }

    &:hover,
    &:active {
      text-decoration: none;
      color: $color-navient-dark-blue-6;
      background-color: $white;
      border-color: $color-gray-5;
      box-shadow: none;

      &:after {
        color: $color-navient-dark-blue-6 !important;
      }
    }

    span {
      display: none;
    }

    &:after {
      content: "\f078";
      color: $color-msb-gray-6 !important;
      font-family: "Font Awesome 5 Free";
      display: inline-block;
      font-size: 0.625rem;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      font-weight: 900;
      line-height: 0;
      margin-left: 8px !important;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      border: none;
      position: relative;
      top: 2px;
    }
  }

  .dropdown-menu.dropdown-menu-sm,
  .dropdown-menu-sm.dropdown-menu-sm {
    min-width: 64px;
    max-width: 64px;
    border: none;
    box-shadow: 0px 4px 6px rgba(31, 41, 55, 0.1),
    0px 2px 4px rgba(31, 41, 55, 0.06);

    a {
      width: 100%;

      &:hover {
        background-color: $color-gray-2;
      }
    }
  }
}