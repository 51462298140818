.web-settings {
  .card {
    .card-header {
      padding: 12px 24px;
      height: auto;
    }

    .card-body {
      p {
        color: $color-gray-7;
      }

      .site-settings {
        .navigation-links {
          .buttons {
            display: flex;
            align-items: center;
            margin-top: 16px;

            button {
              line-height: 1;
              height: 24px;
              width: 24px;
              padding: 4px;
            }
          }
        }

        .site-urls {
          .form-group .input-group .input-group-text {
            color: $color-msb-gray-6;
          }
          
          @media (max-width: 767px) {
            .row {
              &:last-of-type {
                .form-group {
                  &:last-of-type {
                    margin-top: 16px;
                  }
                }
              }
            }
          }
        }

        .navigation-links {
          .form-group .input-group .input-group-text {
            color: $color-msb-gray-6;
          }
          
          @media (max-width: 767px) {
            .row {
              &:last-of-type {
                .form-group {
                  &:first-of-type {
                    margin-bottom: 16px;
                  }
                }
              }
            }
          }

          @media (max-width: 575px) {
            .row {
              .form-group {
                margin-bottom: 16px;

                &:last-of-type {
                  label {
                    display: none;
                  }
                }

                .buttons {
                  margin-top: 0;
                }
              }
            }
          }

          @media (max-width: 991px) {
            .row {
              .form-group {
                &:first-of-type {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }

      .site-style {
        .color-palettes {
          button {
            &.color-box {
              height: 32px;
              width: 32px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
  
            &.color-button {
              padding: 4px 11px;
              height: 32px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }

        .logo-upload {
          .container-site-style-logo-preview {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 68px;
          }

          .container-site-style-logo-upload {
            padding-top: 30px;
            
            label {
              &.btn-file-picker {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-bottom: -30px;
                height: 100px;
                width: 100px;
                background-color: $color-gray-3;
                border: 1px dashed $color-gray-5;
                border-radius: .25rem;
                cursor: pointer;

                @media (max-width: 767px) {
                  margin-bottom: 0;
                }

                svg {
                  color: $color-gray-7;
                  stroke: whitesmoke;
                  stroke-width: 20px;
                  margin-bottom: 6px;
                }
              }
            }

            input {
              display: none;
            }
          }
        }
      }
    }
  }

  &-footer {
    display:grid;
    grid-template-rows:1fr;
    grid-template-columns: auto auto auto 1fr auto;
    grid-template-areas: "preview draft spinner space submit";
    justify-content:left;
    gap: 8px;

    button:last-of-type {
      grid-area: submit;
    }

    @media (max-width: 768px) {
      grid-template-rows: inherit;
      grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
      grid-template-areas: none;

      button:last-of-type {
        grid-area: auto;
      }
    }
  }
}