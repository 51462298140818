.show-loading-animation.rect-shape,
.show-loading-animation.round-shape,
.show-loading-animation.text-row,
.show-loading-animation .rect-shape,
.show-loading-animation .round-shape,
.show-loading-animation .text-row {
  background-color: $color-gray-4 !important;
  max-height: 100%;
  width: 100% !important;
  height: 1em;
  margin-top: 0px;
  border-radius: 4px;
  animation: react-placeholder-pulse 1.5s infinite;
}

@keyframes react-placeholder-pulse {
  0% {
    opacity: .6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .6;
  }
}

.summaryLoading {
  padding: 0 !important;
}