.module {
    all: revert;
  }

  /* .row:not(:last-child)  {
    margin-bottom: 10px;
  } */
  

#receiptSectionContainer .row {
  margin-bottom: 8px;
}

@media print {
  #receiptSectionContainer .row {
    margin-bottom: 4px;
  }
}

#receiptSectionContainer img.react-page-plugins-content-image {
  display: block;
  margin: 0 auto;
}