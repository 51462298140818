.utility-nav {
  min-height: 48px;
  margin: 0;
  padding: 4px 24px;

  .nexus-logo {
    height: 24px;
  }

  .user-avatar {
    display: flex;
    background-color: $color-gray-3;
    color: $color-msb-gray-6;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    text-indent: -99999px;
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
  }

  .current-user {
    font-size: $font-size-base;
  }
}
