.cancel-modal-body {
    padding: 2rem;
}

.cancel-modal-actions {
    float: right;
    padding-top: 32px;
}

.cancel-modal-body .btn {
    width: 82px;
    height: 36px;
    padding: 0px;
}

.cancel-modal-body .btn-secondary {
    color: #414141;
    background: #FFFFFF;
}

.cancel-modal-body .btn-primary {
    background: #0057B6;
    margin-left: 8px;
}