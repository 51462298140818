.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 575px) {
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
}


/* Hide scrollbar for IE, Edge and Firefox */

html {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}