// Payment Channel Add Modal

// Web API
.webAPI-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .input-group {
    display: flex;

    input {
      flex: 2;
    }

    #btnCopyClipboard {
      background: $color-gray-3;
      border-left: 1px solid $color-gray-3;
      border-radius: 0 4px 4px 0;

      &:focus,
      &:active {
        border-left-color: $color-navient-dark-blue-6;
      }
    }
  }
}


// Required Features
.required-header {
  border-top: 1px solid $color-gray-4;
  margin-top: 35px;
  padding-top: 35px;
}

.required-grid {
    display: flex;
    flex-wrap: wrap;
}

