.toggle-switch {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    
    .toggle-label {
      padding: 0 8px;
    }
  }  
  
  
  .notification-toggle-switch {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .toggle-label {
          padding: 0 8px;
        }
  
        @media (max-width: 425px) {
          flex-direction: column; 
        }
  }
