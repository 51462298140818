/**
 * = Sections
 */

.section {
    position: relative;
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 3;
}

.section-header {
    position: relative;
    padding-top: $spacer * 9;
    padding-bottom: $spacer * 3;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

@include media-breakpoint-up(sm) {
    .section {
        position: relative;
        padding-top: $spacer * 5;
        padding-bottom: $spacer * 5;
    }
    .section-header {
        position: relative;
        padding-top: $spacer * 8;
        padding-bottom: $spacer * 8;
        &.section-sm{
            padding-top: $spacer * 4;
            padding-bottom: $spacer * 3;
        }
    }
    .section-xl {
        padding-top: $spacer * 8;
        padding-bottom: $spacer * 8;
    }
    .section-lg {
        padding-top: $spacer * 6;
        padding-bottom: $spacer * 6;
    }
    .section-sm {
        padding-top: $spacer * 3;
        padding-bottom: $spacer * 3;
    }
}

@include media-breakpoint-up(lg) {
    .section {
        position: relative;
        padding-top: $spacer * 6;
        padding-bottom: $spacer * 6;
    }
    .section-header {
        position: relative;
        padding-top: $spacer * 10;
        padding-bottom: $spacer * 10;
        &.section-sm{
            padding-top: $spacer * 4;
            padding-bottom: $spacer * 3;
        }
    }
    .section-xl {
        padding-top: $spacer * 10;
        padding-bottom: $spacer * 10;
    }
    .section-lg {
        padding-top: $spacer * 8;
        padding-bottom: $spacer * 8;
    }
    .section-sm {
        padding-top: $spacer * 4;
        padding-bottom: $spacer * 4;
    }
}

// Hero sections
.section-hero {
    @include media-breakpoint-up(md) {
        height: 100vh;
    }
}

//Section delimiter
.line-bottom{
    &::after{
        content: '';
        display: block;
        width: 80%;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -40%;
        height: 1px;
        background: radial-gradient(ellipse at center,$light 0,rgba(255,255,255,0) 80%);
    }
}

// Profile cover
.section-profile-cover {
    height: 580px;
    background-size: cover;
    background-position: center center;
    @include media-breakpoint-down(md) {
        height: 400px;
    }
}

// Components section
.components-section {
    >.form-control {
        +.form-control {
            margin-top: .5rem;
        }
    }
    >.nav+.nav,
    >.alert+.alert,
    >.navbar+.navbar,
    >.progress+.progress,
    >.progress+.btn,
    .badge,
    .btn {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    .btn-group {
        margin-top: .5rem;
        margin-bottom: .5rem;
        .btn {
            margin: 0;
        }
    }
    .alert {
        margin: 0;
        +.alert {
            margin-top: 1.25rem;
        }
    }
    .badge {
        margin-right: .5rem;
    }
    .modal-footer {
        .btn {
            margin: 0;
        }
    }
}

// Index Page
.presentation-box {
    position: relative;
    // phone
    .gadget {
        height: 500px;
        max-width: 100%;
    }
    .pricing-card,
    .dropdown-btn-img,
    .blog-card,
    .profile-card,
    .navbar-img,
    .modal-img,
    .social-btn-img {
        position: absolute;
        @include box-shadow($box-shadow);
        @include border-radius($border-radius);
    }
    .profile-card {
        left: 67%;
        top: 19%;
        height: 100px;
    }
    .navbar-img {
        left: 50%;
        top: 56%;
        height: 65px;
    }
    .modal-img {
        left: 38%;
        top: 80%;
        height: 100px;
    }
    .blog-card {
        left: 2%;
        top: 20%;
        height: 60px;
    }
    .pricing-card {
        left: 15%;
        top: 47%;
        height: 125px;
    }
    .social-btn-img {
        left: 51%;
        top: 78%;
        height: 25px;
    }
    .dropdown-btn-img {
        left: 25%;
        top: 10%;
        height: 25px;
    }
    // tablet
    @include media-breakpoint-up(md) {
        .gadget {
            height: 600px;
            max-width: 100%;
        }
        .pricing-card,
        .dropdown-btn-img,
        .blog-card,
        .profile-card,
        .navbar-img,
        .modal-img,
        .social-btn-img {
            position: absolute;
            @include box-shadow($box-shadow);
            @include border-radius($border-radius);
        }
        .profile-card {
            left: 65%;
            top: 12%;
            height: 150px;
        }
        .navbar-img {
            left: 51%;
            top: 55%;
            height: 80px;
        }
        .modal-img {
            left: 36%;
            top: 80%;
            height: 100px;
        }
        .blog-card {
            left: 7%;
            top: 20%;
            height: 80px;
        }
        .pricing-card {
            left: 18%;
            top: 40%;
            height: 150px;
        }
        .social-btn-img {
            left: 60%;
            top: 80%;
            height: 25px;
        }
        .dropdown-btn-img {
            left: 30%;
            top: 9%;
            height: 25px;
        }
    }
    // macbook
    @include media-breakpoint-up(lg) {
        width: 650px;
        .gadget {
            height: auto;
        }
        .pricing-card,
        .dropdown-btn-img,
        .blog-card,
        .profile-card,
        .navbar-img,
        .modal-img,
        .social-btn-img {
            position: absolute;
            @include box-shadow($box-shadow);
            @include border-radius($border-radius);
        }
        .profile-card {
            left: 75%;
            top: 3%;
            height: 200px;
        }
        .navbar-img {
            left: 55%;
            top: 65%;
            height: 120px;
        }
        .modal-img {
            left: 19%;
            top: 67%;
            height: 170px;
        }
        .blog-card {
            left: 7%;
            top: 3%;
            height: 100px;
        }
        .pricing-card {
            left: 0%;
            top: 30%;
            height: 165px;
        }
        .social-btn-img {
            left: 2%;
            top: 78%;
            height: 25px;
        }
        .dropdown-btn-img {
            left: 30%;
            top: -12%;
            height: 25px;
        }
    }
}

// Pages section
.card-box {
    @include perspective(900px);
    width: 250px;
    position: relative;
    .card-component {
        @include transform-style(preserve-3d);
        position: relative;
        height: 300px;
        @include media-breakpoint-up(lg) {
            height: 450px;
        }
        &.card-component-lg{
            height: auto;

            @include media-breakpoint-up(xs) {
                max-height: 800px;
            }

            @include media-breakpoint-up(md) {
                max-height: 410px;
            }
        }
        &.card-component-xs{
            height: auto;
            max-height: 230px;
        }
        .front {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            @include box-shadow($box-shadow-lg);
            @include border-radius($border-radius);
            @include backface-visibility(hidden);
            @include transform(rotateY(24deg));
            &:hover {
                cursor: pointer;
            }
        }
    }
    .page-card {
        @include box-shadow($box-shadow-lg);
        @include border-radius($border-radius);
        &:hover {
            cursor: pointer;
        }
    }
    @include media-breakpoint-down(md) {
        width: 200px;
    }
    @include media-breakpoint-up(lg) {
        width: 260px;
    }
}

.copy-docs {
    position: absolute;
    top: 5px;
    right: 5px;
    transition: $transition-base;
    &.copied {
        background: $success;
    }
    &:hover {
        cursor: pointer;
    }
}

.index-icon {
    position: absolute;
    font-size: 38px;
    color: #dcdcdc;
    transition: 0.3s all ease;
}

.index-icon-javascript {
    bottom: 80px;
    left: 40px;
}

.index-icon-javascript:hover {
    color: #f1da1c;
}

.index-icon-bootstrap {
    bottom: -150px;
    right: -7%;
}

.index-icon-bootstrap:hover {
    color: #553d7c;
}

.icon-sass:hover {
    color: #CD6799;
}

.index-icon-code {
    bottom: 180px;
    left: -80px;
}

.index-icon-code:hover {
    color: #ff7f66;
}

.index-icon-gulp:hover {
    color: #e34a4f;
}

.index-icon-gulp {
    left: 250px;
    bottom: -10px;
}

.index-icon-html5 {
    right: 30px;
    bottom: 150px;
}

.index-icon-html5:hover {
    color: #e54b20;
}

.index-icon-css3 {
    right: 40px;
    bottom: -20px;
}

.index-icon-css3:hover {
    color: #e54b20;
}

.index-icon-npm {
    right: 180px;
    bottom: 20px;
}

.index-icon-npm:hover {
    color: #cc3f3d;
}

.index-icon-fontawesome {
    right: 340px;
    bottom: -40px;
}

.index-icon-fontawesome:hover {
    color: #3499EF;
}

.index-icon-illustrations {
    left: 20px;
    bottom: -40px;
}

.index-icon-illustrations:hover {
    color: $secondary;
}