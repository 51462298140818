.gila-settings {
  h3 {
    font-weight: $font-weight-bold;
  }

  p {
    color: $color-gray-7;
  }

  form {
    margin-top: 24px;

    h3 {
      margin-bottom: 24px;
    }

    hr {
      background-color: $color-gray-4;
      margin-top: 23px;
      margin-bottom: 39px;
      opacity: 1;
    }

    .form-group {
      margin-bottom: 16px;
    }

    button {
      margin-top: 32px;
    }
  }

  @media (min-width: 320px) {
    .mb-sm-4 {
      margin-bottom: 1.5rem;
    }
  }
}