/**
 * = Payments
      - Point of Sale
      - Chargebacks
      - Quick Pay
      - Refunds
      - Terminal Pay
      - Voids
      - Create Chargebacks
 */

// Payments
.container-payment {
  .transactionTitle {
    margin-bottom: 24px;

    h2 {
      font-weight: $font-weight-bold;
      margin-bottom: 4px;
    }

    p {
      color: $color-gray-7;
      margin-bottom: 0;
    }
  }

  .payment-channel,
  .payment-type,
  .payment-address {
    .form-group {
      margin-bottom: 16px !important;
    }
  }

  .payment-type,
  .payment-address {
    margin-top: 27px;
  }

  .payment-channel,
  .payment-type {
    padding-bottom: 11px;
    border-bottom: 1px solid $color-gray-4;
  }

  .payment-type {
    .price-qty-add {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;

      @media (min-width: 768px) and (max-width: 1860px) {
        grid-template-columns: 1fr;
        gap: 0;
      }

      @media (max-width: 424px) {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    .btn-add-item {
      margin-top: 29px;

      @media (min-width: 768px) and (max-width: 1280px) {
        margin-top: 0;
      }

      @media (max-width: 424px) {
        margin-top: 0;
      }
    }
  }

  .container-transaction-summary {
    h2 {
      font-weight: $font-weight-bold;
      margin-bottom: 24px;
    }

    h3 {
      font-weight: $font-weight-bold;

      svg {
        position: relative;
        top: -5px;
      }
    }

    .list-group-item {
      border: none;
      border-bottom: 1px solid $color-gray-4;
      padding: 19px 0;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
      }

      .summaryDetail {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: baseline;
        margin-bottom: 16px;
        gap: 24px;

        span {
          display: flex;
          justify-content: end;
        }

        @media (max-width: 425px) {
          grid-template-columns: 1fr;
          gap: 8px;

          span {
            justify-content: start;
          }
        }

        &:nth-of-type(2) {
          align-items: center;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        h3 {
          margin-bottom: 0;
        }

        .input-group-text {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }

      .actionBox {
        margin-top: 48px;

        .summaryButtons {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          margin-right: auto;
          margin-left: auto;

          .btn-primary {
            margin-bottom: 8px;
          }

          .alert {
            display: grid;
            grid-template-columns: 24px auto;
            grid-column-gap: 16px;
            -webkit-column-gap: 16px;
            column-gap: 16px;
            // align-items: center;
            margin-top: 24px;
            margin-bottom: 0;

            svg {

            }
          }
        }
      }
    }
  }
}

// Payment Details
.container-payment-details {
  div.row {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .transactionDetail {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-bottom: 8px;

    @media (max-width: 374px) {
      grid-template-columns: 1fr;
      gap: 0;
      margin-bottom: 16px;
    }

    h3 {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      justify-content: flex-start !important;
    }
  }

  .transactionDetailReport {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 8px;

    h3 {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .list-group-item {
    border: none;
    border-bottom: 1px solid $color-gray-4;
    padding: 19px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .order-md-first {
    margin-bottom: 30px;
  }

  // Transaction Details Container (Left)
  .container-transaction-details {
    h3 {
      font-weight: $font-weight-bold;
    }

    .transactionTitle {
      margin-bottom: 24px;

      h2 {
        font-weight: $font-weight-bold;
        margin-bottom: 4px;
      }

      p {
        color: $color-gray-7;
        margin-bottom: 0;
      }
    }

    .transactionList {
      .list-group-item {
        &:last-of-type {
          padding: 35px 0 24px;
        }
      }

      .btn-toolbar {
        .btn {
          margin-left: 8px;
        }
      }
    }
  }

  // Transaction Summary Container (Right)
  .container-transaction-summary {
    h2 {
      font-weight: $font-weight-bold;
      margin-bottom: 24px;
    }

    h3 {
      font-weight: $font-weight-bold;

      svg {
        position: relative;
        top: -5px;
      }
    }

    .list-group-item {
      padding-bottom: 27px !important;

      &:last-of-type {
        padding-top: 27px !important;
        padding-bottom: 0 !important;
      }

      h2 {
        margin-bottom: 24px;
      }

      p {
        display: none;
      }

      .errorDetail{
        color: $color-dust-red-red-6;
        margin-bottom: 16px;
      }
      
      .summaryDetail {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: baseline;
        margin-bottom: 16px;
        gap: 24px;

        span {
          display: flex;
          justify-content: end;
        }

        @media (max-width: 425px) {
          grid-template-columns: 1fr;
          gap: 8px;

          span {
            justify-content: start;
          }
        }

        &:nth-of-type(2) {
          align-items: center;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        h3 {
          margin-bottom: 0;
        }

        .input-group-text {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }

      .actionBox {
        margin-top: 48px;

        .summaryButtons {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          margin-right: auto;
          margin-left: auto;

          .btn-primary {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  // Related transactions and Additional details tables
  .container-related-transactions,
  .container-additional-details {
      .card-title {
          margin-right: -24px;
          margin-bottom: 24px;
          margin-left: -24px;
          padding: 0 24px 24px;
          border-bottom: 1px solid $color-gray-5;

          h2 {
              margin-bottom: 0;
          }
      }

      .additionalTransactionDetails {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
          margin-bottom: 8px;

          @media (max-width: 984px) {
              grid-template-columns: 1fr;
          }

          @media (max-width: 374px) {
            gap: 0;
            margin-bottom: 16px;
            overflow-y: scroll;
        }
          h3 {
              font-weight: $font-weight-bold;
              margin-bottom: 0;
          }

          &:last-of-type {
              margin-bottom: 0;
          }

          span {
              justify-content: flex-start !important;
          }
      }
  }
  }


// Modal with Transaction Details & Summary
.modal-body {
  .container-transaction-details {
    .transactionTitle {
      margin-bottom: 24px;

      h2 {
        font-weight: $font-weight-bold;
        margin-bottom: 4px;
      }

      p {
        color: $color-gray-7;
        margin-bottom: 0;
      }
    }

    .transactionList {
      &.transactionListModal {
        .list-group-item {
          &:last-of-type {
            padding: 19px 0 0;
            margin-bottom: 0;
            border-bottom: none;
          }
        }
      }
    }

    .list-group {
      .list-group-item {
        border: none;
        border-bottom: 1px solid $color-gray-4;
        padding: 19px 0;


        .transactionDetail {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
          margin-bottom: 8px;

          h3 {
            font-weight: $font-weight-bold;
            margin-bottom: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          margin-bottom: 24px;
          padding: 35px 0 35px;
        }
      }
    }

    .btn-toolbar {
      .btn {
        margin-left: 8px;
      }
    }
  }

  .container-transaction-summary {
    h2 {
      font-weight: $font-weight-bold;
      margin-bottom: 24px;
    }

    h3 {
      font-weight: $font-weight-bold;

      svg {
        position: relative;
        top: -5px;
      }
    }

    .list-group-item {
      border: none;
      border-bottom: 1px solid $color-gray-4;
      padding: 19px 0;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
      }

      .summaryDetail {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: baseline;
        margin-bottom: 16px;
        gap: 24px;

        span {
          display: flex;
          justify-content: end;
        }

        @media (max-width: 425px) {
          grid-template-columns: 1fr;
          gap: 8px;

          span {
            justify-content: start;
          }
        }

        &:nth-of-type(2) {
          align-items: center;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        h3 {
          margin-bottom: 0;
        }

        .input-group-text {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }

      .transactionDetail {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        margin-bottom: 8px;

        @media (max-width: 374px) {
          grid-template-columns: 1fr;
          gap: 0;
          margin-bottom: 16px;
        }

        h3 {
          font-weight: $font-weight-bold;
          margin-bottom: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }


      .errorDetail{
        color: red;
        margin-bottom: 16px;
      }

      .actionBox {
        margin-top: 48px;

        .summaryButtons {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          margin-right: auto;
          margin-left: auto;

          .btn-primary {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}


// Receipt templates
.container-receipt-details {
  margin-left: auto;
  margin-right: auto;
  width: 600px;

  @media (max-width: 996px) {
    width: auto;
  }

  .receipt-header {
    text-align: center;

    h1 {
      margin-top: 24px;
      margin-bottom: 4px;
    }

    h3 {
      font-weight: 700;
      margin-bottom: 0;
    }

    p {
      color: $color-gray-7;
      margin-bottom: 0;
    }

    .btn-toolbar {
      justify-content: center;
      gap: 8px;
      margin-top: 24px;
    }
  }

  // .receipt-body {
  //   margin-top: 48px;
  // }

  .order-body {
    @media (max-width: 996px) {
      .receipt-detail {
        margin-bottom: 8px;

        .order-line {
          margin-bottom: 8px;
        }
      }
    }
    
    span {
      margin-bottom: 8px;
    }
  }
  
  .receipt-body,
  .order-body {
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    @media (max-width: 996px) {
      align-items: flex-start;
    }

    img {
      display: block;
      margin: 0 auto;
    }
    
    .receipt-detail {
      display: grid;
      grid-template-columns: repeat(2, 300px);
      margin-top: 8px;

      &:first-of-type {
        margin-top: 0;
      }
      
      @media (max-width: 996px) {
        grid-template-columns: 1fr;
      }
      
      h6{
        font-weight: 700;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 0;
      }
      
      span {
        text-align: left;

        @media (max-width: 996px) {
          text-align: left;
        }
      }

      .receipt-amount {
        font-size: inherit;
        font-weight: 700;
      }
    }
  }
  
  .receipt-disclaimer {
    a {
      text-decoration: none;
  
      &:hover {
        color: $color-navient-dark-blue-5;
      }
    }
  }

  .receipt-buttons {
    display: flex;
    justify-content: space-between;
    
    .btn-toolbar {
      display: flex;
      gap: 8px;
    }

    @media (max-width: 996px) {
      flex-direction: column-reverse;
      gap: 8px;
    }
  }
}

  .print-receipt-buttons {
    display: flex;
    justify-content: center;

    .btn-toolbar {
        display: flex;
        gap: 8px;
    }

    @media (max-width: 996px) {
        flex-direction: column-reverse;
        gap: 8px;
    }
}


// .receiptButtons {
//   margin-top: 54px;
// }

