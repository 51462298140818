.container-adGroup {
  &.container,
  &.container-fluid {
    margin: 0;
    margin-top: -2px;
    padding: 0;
  }

  .card {
    border-radius: 0;

    .card-body {
      padding: 0px 24px 16px;
    }
  }
}

.adGroup {
  display: flex;
  align-items: center;
  background-color: $white;

  .userAvatar {
    flex-shrink: 0;
  }

  .userDetails {
    margin-left: 20px;

    h1 {
      margin-bottom: 0;
    }

    h4 {
      margin-bottom: 12px;
    }

    span {
      display: block;
      color: $color-gray-7;
      margin-top: 8px;
    }
  }
}

.ad-avatar {
  display: flex;
  background-color: $color-gray-3;
  color: $color-msb-gray-6;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  text-indent: -99999px;
  height: 72px;
  width: 72px;
  min-height: 72px;
  min-width: 72px;
}

.dot {
  height: 70px;
  width: 70px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}