.text-block {
    padding-top: 13px;
    padding-bottom: 14px;
}

.button-spinner {
    margin-left: 10px;
}

.table-spinner {
    position: relative;
    top: -30px;
}