/*
* = Buttons
*/
.btn {
  font-size: 0.875rem;
  font-weight: 400;
  box-shadow: none;
  padding: 0.563rem 1rem;
  white-space: nowrap;
  @include border-radius($border-radius-md);

  &.btn-circle {
    border-radius: $circle-radius;
  }

  &.btn-md {
    padding: 0.65rem 1.25rem;
  }

  &.btn-sm {
    padding: 0.175rem 0.45rem;
    font-size: 0.7rem;
  }

  .spinner-border {
    display: inline-block;
    vertical-align: -0.2em;
    margin-right: 1px;
  }

  &:disabled,
  &.disabled {
    opacity: 1;
  }
}

.btn-icon {
  margin-right: .5rem;
  margin-left: .3rem;
}

.btn-icon-only {
  width: 2.575rem;
  height: 2.575rem;
  padding: 0;
  @include display-flex();
  @include justify-content(center);
  @include align-items(center);

  &.btn-xs {
    width: 1.7rem;
    height: 1.7rem;
  }

  &.btn-sm {
    width: 2rem;
    height: 2rem;
  }
}

.btn-primary {
  &:focus {
    background-color: $color-navient-dark-blue-6;
    box-shadow: inset 0 0 0 2px $color-navient-dark-blue-6,
      inset 0 0 0 3px $white;
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }

  &:hover,
  &:active {
    text-decoration: none;
    background-color: $color-navient-dark-blue-5;
    border-color: $color-navient-dark-blue-5;
  }

  &:not(:disabled):active,
  &:not(:disabled).active {
    background-color: $color-navient-dark-blue-7;
  }

  &:focus:active {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $color-msb-gray-3;
    background-color: $color-gray-3;
    border-color: $color-gray-5;
    cursor: not-allowed;
  }

  svg {
    margin-right: 8px;
  }
}

.btn-outline-secondary {
  background-color: $white;
  color: $color-msb-gray-6;
  border-color: $color-gray-5;

  &--small {
    padding: 6px;
    min-width: 24px;
    min-height: 24px;
    display: flex;

    svg {
      height: 16px;
      width: 16px !important;
    }
  }

  &:focus {
    border: 1px solid $color-navient-dark-blue-6;
    box-shadow: inset 0 0 0 1px $color-navient-dark-blue-6;
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }

  &:hover,
  &:active {
    text-decoration: none;
    color: $color-navient-dark-blue-6;
    background-color: $white;
    border-color: $color-gray-5;
  }

  &:not(:disabled):active,
  &:not(:disabled).active {
    outline: 0;
    box-shadow: none;
    border: 1px solid $color-navient-dark-blue-7;
    color: $color-navient-dark-blue-7;
  }

  &:focus:hover {
    border: 1px solid $color-navient-dark-blue-6;
  }

  &:focus:active {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $color-msb-gray-3;
    background-color: $color-gray-3;
    border-color: $color-gray-5;
    cursor: not-allowed;
  }
}

.btn-link {
  color: $color-navient-dark-blue-6;

  &[href] {
    line-height: 21px;
  }

  &:focus {
    border: 1px solid $color-navient-dark-blue-6;
    box-shadow: inset 0 0 0 1px $color-navient-dark-blue-6;
    color: $color-navient-dark-blue-6;
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;

    &.bh-text-button--loading {
      color: transparent;
    }
  }

  &:hover,
  &:active {
    text-decoration: none;
    background-color: $color-gray-2;
    color: $color-navient-dark-blue-5;
    border: 1px solid transparent;
  }

  &:focus:hover {
    border: 1px solid $color-navient-dark-blue-6;
  }

  &:not(:disabled):active,
  &:not(:disabled).active {
    background-color: $color-gray-2;
    outline: 0;
    box-shadow: none;
    border: 1px solid transparent;
    color: $color-navient-dark-blue-7;
  }

  &:focus:active {
    box-shadow: none;
  }

  &:disabled {
    background-color: transparent;
    color: $color-msb-gray-3;
    cursor: not-allowed;
  }
}

// Tab Scroll Buttons
.btn-scroll-left,
.btn-scroll-right {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  color: $color-msb-gray-6;

  &:hover,
  &:active {
    background-color: $white;
  }

  &:not(:disabled):active,
  &:not(:disabled).active {
    background-color: $white;
  }
}

.btn-scroll-left {
  border-radius: 4px 0 0 0;
  box-shadow: 0 0 12px rgba(65, 65, 65, 0.08);
  clip-path: inset(0px -12px 0px 0px);

  &:not(:disabled):active,
  &:not(:disabled).active {
    box-shadow: 0 0 12px rgba(65, 65, 65, 0.08);
    clip-path: inset(0px -12px 0px 0px);
  }
}

.btn-scroll-right {
  border-radius: 0 4px 0 0;
  box-shadow: 0 0 12px rgba(65, 65, 65, 0.08);
  clip-path: inset(0px 0px 0px -12px);

  &:not(:disabled):active,
  &:not(:disabled).active {
    box-shadow: 0 0 12px rgba(65, 65, 65, 0.08);
    clip-path: inset(0px 0px 0px -12px);
  }
}



//
// Block button
//
.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  +.btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

.copy-code-button {
  position: absolute;
  top: 22px;
  right: 20px;
  background-color: transparent;
  color: $gray-600;
  border: 0;
  box-shadow: none;

  &:hover {
    background-color: $primary;
    color: $white;
    border: 0;
    box-shadow: none;
  }
}

.copy-code-text {
  position: absolute;
  top: 24px;
  right: 90px;
}