.chrome-picker-container {
  .chrome-picker-wrapper {
    z-index: 99;
    position: absolute;
  }
  
  .chrome-picker-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .chrome-picker {
    box-shadow: 0px 10px 10px rgba(31, 41, 55, 0.04),
    0px 20px 25px rgba(31, 41, 55, 0.1) !important;
  }
}