.tab-content {
  .aprovalChecklistWrapper {
    form {
      .row {
        margin-bottom: 8px;

        .form-group {
          margin-bottom: 0;
        }

        .approval-comments {
          margin-top: 8px;
        }
      }
    }
  }
}