.approvalsWidget{
    background-color: $white;
    margin-top: 24px;
    flex:50%;
    padding: 12px;
}

.myControlClassName{
    border: none !important; 
    padding: 0 !important;
    color: $color-navient-dark-blue-6 !important;
}
.myControlClassName:hover { 
    cursor: pointer !important;
  }

.nav-link {
    color: $color-msb-gray-6;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.nav-link.active{
    color: $color-navient-dark-blue-6;
    font-weight: $font-weight-bold;
    border-bottom: $color-navient-dark-blue-6 2px solid;
}

.req-action-dropdown {
    border: none !important;
    
}

.req-action-dropdown > button {
    color: $color-navient-dark-blue-6;
    padding-top: 0;
    padding-bottom: 0;
}

// .btn-check:focus + .btn, .btn:focus {
//     box-shadow: none;
// }
