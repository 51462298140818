.flexContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.calendarDayButtons{
    width: 50px;
    height: 50px;
    border-radius: 25px !important;
}

.emailRecurrence,
.table-configuration {
    margin-top: 24px;

    h2 {
      font-weight: 700;
      margin-bottom: 4px;
    }

    p {
      color: $color-gray-7;
    }

    button {
      &.btn-outline-secondary {

        &:disabled {
          background-color: $color-gray-3;
          color: $color-msb-gray-3;
          border-color: $color-gray-5;
        }
      }
    }
}

.emailRecurrenceRadio{
    margin-top: 10px;
    align-items: center;
}
.emailRecurrenceButtons,
.table-configuration-buttons {
    margin-top: 48px;
}

.emailRecurrenceCancel {
    float: right;
}
