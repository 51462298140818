.msb-portal {
  &__header {
    background-color: #ffffff;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    padding: 24px 12px;

    .navbar-brand {
      img {
        height: 45px;

        @media (max-width: 424px) {
          height: 30px;
        }
      }
    }
  }

  &__nav {
    .navbar-nav {
      align-items: center;
      justify-content: center;

      @media (max-width: 991px) {
        height: 100vh;
      }
    }

    .nav-item {
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 4px;

      .nav-link {
        color: rgba(65, 65, 65, 1);
        font-weight: 700;
        padding: 0.5rem;

        &.active {
          color: rgba(0, 87, 182, 1);
          border-bottom: none;

          &:hover,
          &:active {
            color: rgba(0, 87, 182, 0.8);
          }
        }

        &:hover,
        &:active {
          color: rgba(65, 65, 65, 0.8);
        }
      }
    }

    &.navbar-light {
      .navbar-toggler {
        border-color: transparent;

        &:focus {
          background-color: transparent;
          box-shadow: inset 0 0 0 2px rgba(0, 87, 182, 1),
            inset 0 0 0 3px #ffffff;
          outline-color: transparent;
          outline-width: 2px;
          outline-style: dotted;
        }
      }
    }
  }

  &__payment-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
    padding: 0 24px;

    img {
      height: 32px;
      width: 32px;
    }
  }
}
