/**
 * = Popovers
 */

.popover {
  border: 0;
  margin-bottom: .375rem !important;
  border-radius: 4px;

  .arrow  {
    position: absolute !important;
    left: calc(50% - 6px) !important;
    bottom: -6px;
    content: '';
    width: 12px;
    height: 12px;
    background: $white;
    transform: rotate(45deg) !important;
    border-radius: 2px;
  }
}


.popover-header {
	font-weight: $font-weight-bold;
}

// Alternative colors
@each $color, $value in $theme-colors {
    .popover-#{$color} {
        @include popover-variant($value);
    }
}