// React Dual Listbox npm
$rdl-input-bg: $white !default;
$rdl-input-disabled-bg: $color-gray-3 !default;
$rdl-input-border: $color-gray-5 !default;
$rdl-input-color: $color-msb-gray-6 !default;
$rdl-btn-bg: $rdl-input-bg !default;
$rdl-btn-bg-active: darken($rdl-btn-bg, 10%);
$rdl-btn-border: $rdl-input-border !default;
$rdl-btn-border-active: darken($rdl-btn-border, 25%);
$rdl-btn-border-hover: darken($rdl-btn-border, 12%);
$rdl-btn-color: $color-msb-gray-6 !default;
$rdl-line-height: 1.5 !default;


.react-dual-listbox {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  input,
  select {
    &:disabled {
      background: $rdl-input-disabled-bg;
      cursor: not-allowed;
    }
  }

  button,
  select {
    line-height: $rdl-line-height !important;
    font-family: inherit;
  }

  select {
    &:focus {
      /* Provide a fallback style for browsers
     that don't support :focus-visible */
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px $color-navient-dark-blue-6;
    color: $color-navient-dark-blue-6;
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
    }

    &:focus:not(:focus-visible) {
      /* Remove the focus indicator on mouse-focus for browsers
     that do support :focus-visible */
      background: transparent;
    }

    &:focus-visible {
      /* Draw a very noticeable focus style for
      keyboard-focus on browsers that do support
      :focus-visible */
      border: 1px solid transparent;
      box-shadow:  0 0 0 1px $color-navient-dark-blue-6;
      color: $color-navient-dark-blue-6;
      outline-color: transparent;
      outline-width: 2px;
      outline-style: dotted;
    }
  }

  .rdl-list-box {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-self: stretch;
  }

  .rdl-filter,
  .rdl-control {
    display: block !important;
    color: $rdl-input-color !important;
    font-size: $font-size-base !important;
    border: 1px solid $rdl-input-border !important;
    border-radius: 4px !important;
    padding: 10px !important;
    width: 100% !important;

    optgroup {
      padding-bottom: 10px;
      border-bottom: 1px solid $color-gray-4;
      margin-bottom: 10px;

      &:last-of-type {
        border: none;
        margin: 0;
        padding: 0;
      }

    }
  }

  .rdl-filter {
    margin-bottom: 10px;
  }

  .rdl-control-container {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  .rdl-control-label {
    font-weight: $font-weight-normal;
    margin-bottom: 0.5rem;
    padding: 0 !important;

    &.rdl-sr-only {
      position: absolute;
      clip: rect(0 0 0 0);
    }
  }

  .rdl-control {
    flex: 1 0 auto;

    option {
      padding: 3px 12px 5px;
      border-radius: 4px;

      &:hover {
        background-color: $color-gray-3;
        cursor: pointer;
      }
    }

    optgroup {
      font: inherit;
      font-weight: 700;
    }
  }

  .rdl-actions {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    margin: 0 10px;

    .rdl-has-header & {
      padding-top: 29px;
    }

    .rdl-has-filter & {
      padding-top: 44px;
    }

    .rdl-has-header.rdl-has-filter & {
      padding-top: 75px;
    }

    // .fa,
    // .fas {
    //   font-family: "Font Awesome 5 Pro";
    //   font-weight: 600;
    // }
  }

  .rdl-actions-right,
  .rdl-actions-left {
    display: flex;
    flex-direction: column;
  }

  .rdl-actions-right {
    margin-bottom: 4px;
  }

  .rdl-move {
    margin-bottom: 4px;
    border: 1px solid $rdl-btn-border;
    border-radius: 4px;
    background: $rdl-input-bg;
    cursor: pointer;
    padding: 5px 10px;
    color: $rdl-btn-color;
    font-size: 12px;

    &:active:not(:disabled) {
      outline: 0;
      background-color: $white;
      box-shadow: none;
      border: 1px solid $color-gray-5;
      color: $color-navient-dark-blue-6;
    }

    &:focus:not(:disabled) {
      background-color: $white;
      border: 1px solid $color-navient-dark-blue-6;
      box-shadow: inset 0 0 0 1px $color-navient-dark-blue-6;
      outline-color: transparent;
      outline-width: 2px;
      outline-style: dotted;
    }

    &:hover:not(:disabled) {
      text-decoration: none;
      color: $color-navient-dark-blue-6;
      background-color: $white;
      border-color: $color-gray-5;
    }

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }

    &:last-child {
      margin-bottom: 0;
    }

    // Decrease spacing between icons
    i {
      margin: 0 -1px;
    }
  }

  .rdl-align-top {
    .rdl-available {
      margin-right: 10px;
    }

    .rdl-selected {
      margin-left: 10px;
    }

    .rdl-control {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .rdl-actions-left,
    .rdl-actions-right {
      flex-direction: row;
      margin: 0;
    }

    .rdl-move {
      flex: 0 1 50%;

      &:first-child {
        margin-bottom: 0;
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

}
