.container-table-search {
    .card {
      .card-body {
        padding: 1rem 1.5rem;
  
        // .advanced-search {
        //   @media (max-width: 388px) {
        //     .btn-primary {
        //       margin-top: 8px;
        //     }
        //   }
        // }
      }
    }
  }
  
  .advanced-search-modal {
    &.modal .modal-dialog .modal-content .modal-body h2 {
        margin-bottom: 0;
    }
  
    .advanced-search-form {
      .advanced-search-form-columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0;
        row-gap: 0;
  
        @media (max-width: 475px) {
          grid-template-columns: 1fr;
        }
        
        
        .form-group {
          margin-bottom: 16px;
          
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        
        .column-key .form-group {
          margin-bottom: 16px;
        }
      }
  
      .advanced-search-footer {
        margin-top: 48px;
      }
    }
  }
  
  .badge-container {
      max-height: 150px;
      padding: 4px;
      width: 100%;
      position: relative;
  }
  
  .badge-tab {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      max-height: inherit;
  }
  
  .badge-pill {
      display: flex;
      align-items: center;
      padding: 0.75em 0.9em;
      font-weight: 400;
      font-size: 0.8rem;
      background-color: white;
      color: #0057b6;
      font-family: "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      border-radius: 50rem;
      border: 0.0625rem solid transparent;
      border-color: #d9d9d9;
  }
  
  .badge-remove {
      color: #cf4539;
      padding: 0;
      margin-left: 8px;
      text-decoration: none;
      font-size: 1rem;
      background-color: transparent;
  }
  
  .badge-remove:hover,
  .badge-remove:focus,
  .badge-remove:active {
      color: #cf4539;
      background-color: transparent;
      text-decoration: none;
  }
  
  .manage-search {
    border: 1px solid $color-gray-4;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 16px 24px;
  
    @media (max-width: 1199px) {
      input {
        margin-bottom: 1rem;
      }
    }
  }
  
  .report-search-summary {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    gap: 24px;
    align-items: center;
  
    @media (max-width: 1024px) {
      grid-template-columns: auto;
      align-items: flex-start;
  
      h2 {
        grid-column: 1 / span 3;
      }
    }
  
    @media (max-width: 425px) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
  
      h2 {
        margin-bottom: 8px !important;
      }
    }
  }
  
  .emaf-search {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  
    input {
      min-width: 100px;
    }
  }