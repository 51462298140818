.spinner-border {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 1rem;
  height: 1rem;
  border: 1.5px solid $color-navient-dark-blue-6;
  border-right-color: transparent;
}

.customPageSpinner .modal-content{
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}