.border-right {
  border-right: 1px solid #F0F0F0;
  margin-right: 12px;

  @media (max-width: 991px) {
    border-right: none;
    border-bottom: 1px solid #F0F0F0;
    margin-right: 0;
    margin-bottom: 24px;
  }
}

// Step tabs - Vertical
.step-nav {
  display: flex;
  flex-direction: column;
  // height: 100%;

  .nav-item {
    &.step-item {
      display: block;
      flex: 1 0 auto;
      padding: 0;
      overflow: visible;
      vertical-align: top;
      position: relative;

      a {
        cursor: auto;

        &:focus,
        &:active {
          box-shadow: none;
        }
      }

      .step-item-container {
        outline: none;

        .step-item-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          border: 1px solid $color-gray-5;
          border-radius: 24px;
          font-size: 0.875rem;
          color: $color-gray-7;
          line-height: 1;
          height: 24px;
          width: 24px;
          margin: 0 16px 0 0;
          float: left;

          &--inprogress {
            .step-icon {
              font-weight: 400;
            }
          }

          &--finished {
            border: 1px solid $color-navient-dark-blue-6;
            color: $color-navient-dark-blue-6;
            font-size: .5rem;

            ~.step-item-tail {
              &:after {
                background: $color-navient-dark-blue-6;
              }
            }

            ~.step-item-content {
              color: $color-msb-gray-6;
            }
          }
        }

        .step-item-tail {
          display: block;
          position: absolute;
          top: 0;
          left: 12px;
          width: 1px;
          height: 100%;
          padding: 28px 0 4px;

          &:after {
            display: inline-block;
            width: 100%;
            height: 1px;
            background: $color-gray-4;
            border-radius: 1px;
            transition: background .3s;
            content: "";
            width: 1px;
            height: 100%;
          }
        }

        .step-item-content {
          display: block;
          color: $color-gray-7;
          min-height: 56px;
          padding-top: 0px;
          overflow: hidden;

          .step-item-title {
            font-size: $font-size-base;
          }
        }
      }

      &:last-child {
        .step-item-container {
          .step-item-tail {
            display: none;
          }
        }
      }

      .active {
        .step-item-container {
          .step-item-icon {
            background-color: $color-navient-dark-blue-6;
            border-color: $color-navient-dark-blue-6;
            color: $white;
          }

          .step-item-content {
            color: $color-msb-gray-6;
            font-weight: normal;
          }
        }
      }
    }

    .nav-link {
      padding: 0;

      &.active {
        border: none;
      }
    }
  }
}

// Horizontal tabs
.tab-nav {
  border-bottom: 1px solid $color-gray-5;
  margin: -24px -24px 24px;
  padding-left: 24px;
  padding-right: 24px;

  .nav-item {
    margin: 0 16px;

    .nav-link {
      font-size: $font-size-base;
      padding: 16px 0;
      white-space: nowrap;

      &.active {
        color: $color-navient-dark-blue-6;
        font-weight: 700;
        border-bottom: $color-navient-dark-blue-6 2px solid;
      }
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

// Vertical tabs 
.tab-nav-left {
  border-right: 1px solid $color-gray-4;
  height: 100%;

  @media (max-width: 1199px) {
    border-right: none;
    margin-bottom: 24px;
  }

  .nav-item {
    .nav-link {
      font-size: $font-size-base;

      &.active {
        background-color: $color-navient-dark-blue-1;
        color: $color-navient-dark-blue-6;
        font-weight: 700;
        border-right: 3px solid $color-navient-dark-blue-6;
        border-bottom: none;
        border-radius: 4px 0 0 4px;

        @media (max-width: 1199px) {
          border-radius: 0;
          border-right: none;
          box-shadow: inset 0px -3px 0px 0px $color-navient-dark-blue-6;
        }
      }
    }

    .dropdown-divider {
      margin: 15px 0;
    }
  }
}

// Tab content
.tab-content {
  form {
    .row {
      margin-bottom: 0;

      &:last-of-type {
        .form-group {
          margin-bottom: 0;
        }
      }

      .form-group {
        margin-bottom: 16px;
      }
    }
  }
  
  &.onboard {
    .cardGrid {
      .cardSwitch {
        .toggle-switch {
          &:last-of-type {
            margin-bottom: 0;
            
            @media (max-width: 425px) {
              margin-bottom: 8px; 
            }
          }
        }

        @media (max-width: 425px) {
          &:last-of-type {
            .toggle-switch {
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }

    }

    .manage-footer {
      border-top: none;
      padding-top: 0;
    }
  }

  .manage-footer {
    border-top: 1px solid $color-gray-5;
    margin-top: 48px;
    margin-right: -24px;
    margin-left: -24px;
    padding-top: 16px;
    padding-right: 24px;
    padding-left: 24px;

    .form-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }
  }
}

.tab-form {
  .row {
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 16px;
  }
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  row-gap: 0;
  
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
  
  .toggle-switch {
    &:last-of-type {
      margin-bottom: 8px;
    }
  }
}

.onboarding-complete {
  text-align: center;

  p {
    color: $color-gray-7;
    margin-bottom: 0;
  }

  button {
    margin-top: 48px;
  }
}