.roleSwitch {
  margin-top: 8px;
}

.roleType {
  padding: 0 8px;
}

.roleGrids {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 24px;
  column-gap: 24px;

  @media screen and (max-width: 414px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (min-width: 415px) and (max-width: 995px) {
    grid-template-columns: repeat(2, 1fr);
  }

  h3 {
    font-weight: $font-weight-bold;
    line-height: 1.5rem;
    margin-bottom: 0;
  }

  p {
    color: $color-gray-7;
    margin-bottom: 0;
  }
}

.roleExternalUsers {
  margin-top: 30px;
  margin-bottom: 30px;
}

.roleDialogButtons {
  float: right;
  margin-top: 8px;
}

.roleCancelConfirm {
  float: right;
  margin-left: 4px;
}

.roleSubmit {
  margin-top: 24px;
}

.roleFooterCancel {
  float: right;
}

.roleCancelDialog {
  width: 100%;
  margin: 0
}