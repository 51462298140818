.path {
  animation: draw 3.5s infinite;
}

@keyframes draw {
  50% {
    stroke-dashoffset: 0;
  }
}

.authorizing {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}