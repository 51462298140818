// Default
.nv-tag {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  background: $color-gray-2;
  color: $color-msb-gray-6;
  font-size: 12px;
  line-height: 1;
  list-style: none;
  height: auto;
  min-height: 23px;
  padding: 1px 8px 2px;
  border: 1px solid $color-gray-5;
  border-radius: 12px;
  opacity: 1;
  transition: all .3s;

  // Basic
  &-basic {
    &--add-new {
      border-style: dashed;
    }
  }

  // Colorful
  &-colorful {
    &--blue {
      border-color: $color-navient-dark-blue-3;
      background: $color-navient-dark-blue-1;
      color: $color-navient-dark-blue-6;
    }

    &--cyan {
      border-color: $color-cyan-cyan-3;
      background: $color-cyan-cyan-1;
      color: $color-cyan-cyan-6;
    }

    &--geekblue {
      border-color: $color-geek-blue-geekblue-3;
      background: $color-geek-blue-geekblue-1;
      color: $color-geek-blue-geekblue-6;
    }

    &--gold {
      border-color: $color-calendula-gold-gold-3;
      background: $color-calendula-gold-gold-1;
      color: $color-calendula-gold-gold-6;
    }

    &--green {
      border-color: $color-polar-green-green-3;
      background: $color-polar-green-green-1;
      color: $color-polar-green-green-6;
    }

    &--lime {
      border-color: $color-lime-lime-3;
      background: $color-lime-lime-1;
      color: $color-lime-lime-6;
    }

    &--magenta {
      border-color: $color-magenta-magenta-3;
      background: $color-magenta-magenta-1;
      color: $color-magenta-magenta-6;
    }

    &--orange {
      border-color: $color-sunset-orange-orange-3;
      background: $color-sunset-orange-orange-1;
      color: $color-sunset-orange-orange-6;
    }

    &--purple {
      border-color: $color-golden-purple-purple-3;
      background: $color-golden-purple-purple-1;
      color: $color-golden-purple-purple-6;
    }

    &--red {
      border-color: $color-dust-red-red-3;
      background: $color-dust-red-red-1;
      color: $color-dust-red-red-6;
    }

    &--volcano {
      border-color: $color-volcano-volcano-3;
      background: $color-volcano-volcano-1;
      color: $color-volcano-volcano-6;
    }
  }

  // Status
  &-status {
    &--error {
      border-color: $color-dust-red-red-3;
      background: $color-dust-red-red-1;
      color: $color-dust-red-red-6;
    }

    &--processing {
      border-color: $color-navient-dark-blue-3;
      background: $color-navient-dark-blue-1;
      color: $color-navient-dark-blue-6;
    }

    &--success {
      border-color: $color-polar-green-green-3;
      background: $color-polar-green-green-1;
      color: $color-polar-green-green-6;
    }

    &--warning {
      border-color: $color-calendula-gold-gold-3;
      background: $color-calendula-gold-gold-1;
      color: $color-calendula-gold-gold-6;
    }
  }
}