.order_table {
  &.table {
    thead {
      th {
        background-color: $white;
        font-weight: 700;
        border-bottom-color: $color-gray-4;
      }
    }

    > :not(:last-child)> :last-child>* {
      border-bottom-color: $color-gray-4;
    }

    td,
    th {
      padding: 1rem !important;
    }
  }
}

.payment-col3 {
  text-align: 'end';
}

.payment-col2 {
  text-align: 'start';
}

.auto-pay {
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  align-items: center;

  h2 {
    font-weight: 700;
    margin-bottom: 4px;
  }

  .ap-edit {
    text-align: right;
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .form-footer {}
    }
  }
}

.details_description {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 12px;
  align-items: center;

  h3 {
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }
}

.details_title {
  margin-bottom: 24px;

  h2 {
    font-weight: $font-weight-bold;
    margin-bottom: 4px;
  }
}


.userGuideContainer {
    display: flex;
    justify-content: space-around;
    
}

.userGuideCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    width: 800px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}