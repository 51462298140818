﻿.toast-container {
	position: fixed;
	top: 24px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 2147483647; // maximum range
}

.toast {
	width: auto;
	max-width: 100%;
	font-size: 0.875rem;
	pointer-events: auto;
	background-color: $white;
	background-clip: padding-box;
	border: none;
	box-shadow: 0px 0px 6px rgba(31, 41, 55, 0.05),
		0px 10px 15px rgba(31, 41, 55, 0.1);
	border-radius: 0.25rem;

	.toast-body {
		padding: 9px 16px;
	}
}

.toast-error,
.toast-success,
.toast-warning,
.toast-info {
	color: $color-msb-gray-6;
	background-color: $white;
	background-repeat: no-repeat;
	background-position: left calc(0.875em + 0.125rem) center;
	padding-left: 24px;
}

.toast-error {
	background-image: url(../../../assets/img/icons/ico-error.svg);
}

.toast-success {
	background-image: url(../../../assets/img/icons/ico-success.svg);
}

.toast-warning {
	background-image: url(../../../assets/img/icons/ico-warning.svg);
	}

.toast-info {
	background-image: url(../../../assets/img/icons/ico-info.svg);
}