.cf-card {
  border: 1px solid $color-gray-5;
  margin-bottom: 24px;


  &.card {
    box-shadow: none;

    .card-header {
      background-color: $color-gray-3;
      border-bottom: 1px solid $color-gray-5;
      font-size: 1rem;
      height: auto;
      padding: 12px 16px;
    }

    .card-body {
      padding: 16px 16px 0;

        .conv-fees-grid {
          display: grid;
          grid-template-columns: repeat(6, 1fr) 80px;
          gap: 16px 24px;
          align-items: flex-end;
          padding-bottom: 16px;
          overflow-x: scroll;

          @media (max-width: 1024px) {
            grid-template-columns: repeat(7, 120px);
          }

          .buttons {
            margin-bottom: 8px;

            button {
              line-height: 1;
              height: 24px;
              width: 24px;
              padding: 4px;
            }
          }
          .form-group {
            margin-bottom: 0;
          }
        }
    }
  }

  .row {
    .col {
      .conv-fees-grid {
        .form-group {
          >label {
            display: none;
          }
        }
      }
    }

    &:first-of-type {
      .col {
        .conv-fees-grid {
          .form-group {
            label:first-of-type {
              display: flex;
            }
          }
        }
      }
    }
  }
}