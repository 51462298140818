.details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 24px;
  align-items: baseline;
  margin-bottom: 8px;

  @media (max-width: 375px) {
    grid-template-columns: 1fr;
  }
}