/**
 * = Dropdowns
 */

.dropdown-menu {
  min-width: 12rem;
  border: none;
  box-shadow: 0px 4px 6px rgba(31, 41, 55, 0.1),
    0px 2px 4px rgba(31, 41, 55, 0.06);

    @media (max-width: 991.98px) {
      box-shadow: 0px 4px 6px rgba(31, 41, 55, 0.1),
      0px 2px 4px rgba(31, 41, 55, 0.06) !important;
    }

  .dropdown-header,
  .dropdown-item {
    padding: .5rem 1rem;
    font-size: $dropdown-font-size;
  }

  .dropdown-header,
  .dropdown-item-sm {
    padding: .25rem 5px;
    font-size: $dropdown-font-size;
    text-align: center;
  }

  .dropdown-header {
    color: $dropdown-header-color;
    font-weight: $dropdown-header-font-weight;
  }

  .dropdown-item {
    color: $dropdown-link-color;
    transition: $transition-base;
    font-weight: $dropdown-item-font-weight;
    text-decoration: none;

    &:hover,
    &:active {
      background-color: $color-gray-2;
    }

    &:active {
      background-color: $color-navient-dark-blue-1;
    }

    &a:hover {
      color: $dropdown-item-hover-color;
    }
  }

  .dropdown-item-sm {
    color: $dropdown-link-color;
    transition: $transition-base;
    font-weight: $dropdown-item-font-weight;
    text-decoration: none;
    width: 38px;

    &:hover,
    &:active {
      background-color: $color-navient-dark-blue-5;
    }


    &a:hover {
      color: $dropdown-item-hover-color;
    }
  }

  .show & {
    animation: show-dropdown .2s ease forwards;
  }

  &.dropdown-menu-xs {
    min-width: 120px;
    max-width: 120px;
    border: $border-width solid $light;
    @include box-shadow($box-shadow-sm);
  }

  &.dropdown-menu-sm {
    min-width: 38px;
    max-width: 38px;
    border: $border-width solid $light;
    @include box-shadow($box-shadow-sm);
  }

}

.dropdown-divider {
  margin: 0.375rem 0;
  border-color: $dropdown-border-color;
}

// this helps when not only the button should toggle a dropdown
[data-toggle]:hover {
  cursor: pointer;
}

//remove the caret from Bootstrap by default
.dropdown-toggle {

  &:after,
  .dropright &:after,
  .dropleft &:before,
  .dropup &:after {
    display: none;
  }

  &:hover,
  &:active {
    // border-color: $color-navient-dark-blue-5;

    svg {
      color: $color-navient-dark-blue-5 !important;
    }
  }
}

// Dropown sizes
.dropdown-menu-sm {
  min-width: 100px;
  border: $border-radius-sm;
}

.dropdown-menu-md {
  min-width: 180px;
  border: $border-radius-md;
}

.dropdown-menu-lg {
  min-width: 350px;
  border-radius: $border-radius-lg;

  @include media-breakpoint-down(lg) {
    min-width: 285px;
    transform: translateX(15%);
  }
}

.dropdown-menu-xl {
  min-width: 450px;
  border-radius: $border-radius-xl;

  @include media-breakpoint-down(xl) {
    min-width: 285px;
    transform: translateX(15%);
  }
}

.user-dropdown.dropdown-menu.show {
  right: 0 !important;
  left: auto !important;
}

@include media-breakpoint-down(lg) {
  .notifications-dropdown[style] {
    left: auto !important;
    right: 0 !important;
  }
}