/**
 * = Spacing
 */

@each $size, $value in $spacers {
	.top-#{$size} {
		top: $value;
	}
	.right-#{$size} {
		right: $value;
	}
	.bottom-#{$size} {
		bottom: $value;
	}
	.left-#{$size} {
		left: $value;
	}
}

@each $size, $value in $spacers {
	.margin-top-#{$size} {
		margin-top: $value !important;
	}

	.margin-right-#{$size} {
		margin-right: $value !important;
	}

	.margin-bottom-#{$size} {
		margin-bottom: $value !important;
	}

	.margin-left-#{$size} {
		margin-left: $value !important;
	}
}


.center-vertical-absolute,
.center-horizontal-absolute {
	position: absolute;
}

.center-vertical-absolute {
	top: 50%;
	transform: translateY(-50%);
}

.center-horizontal-absolute {
	left: 50%;
	transform: translateX(-50%);
}

.center-horizontal {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}