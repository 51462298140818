.dashboard-widget {
    &.card {
      height: 100%;

      @media (max-width: 1199px) {
        margin-bottom: 24px;
      }
      
      .card-header {
        padding: 20px 24px;

        @media (max-width: 768px) {
          flex-direction: row;
        }

        button {
          margin-right: -6px;
          padding: 0 6px;

          &:hover,
          &:active {
            background-color: transparent;
          }

          svg {
            color: $color-msb-gray-6;
          }
        }
      }

      .card-body {
        small {
          color: $color-gray-7;
        }
      }
    }
  }
  
  .optics_details_row{
    display: grid;
    grid-template-columns: repeat(2, 1fr);  
    gap: 24px;
    align-items: center;
    margin-top: 8px;

    @media (max-width: 374px) {
      grid-template-columns: 1fr;
      gap: 0;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }
    
    h3 {
      font-weight: $font-weight-bold;
      margin-bottom: 0;

      span {
        color: $color-gray-7;
        font-weight: $font-weight-normal;
      }
    }

    hr {
      margin: 27px 0 !important;
    }
  }
  
  .chargebackStatusButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;

    @media (max-width: 374px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 8px;
    }
  }
  
  // .chargebackStatusCancel{
  //     float: right;
  // }
  
  .opticsUpload{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border: 1px dashed #D9D9D9;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    background-color: $color-gray-3;

    h2{
      color: $color-msb-gray-6;
      font-weight: 400 !important;
      margin-top: 16px;
    }
    span{
        color: #8C8C8C;    
    }

    svg {
      color: $color-navient-dark-blue-6;
      font-size: 2rem;
    }
  }
  
  .filesList,
  .commentList {
    margin-top: 24px;

    .list-group {
      .list-group-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0 !important;
        border-bottom: 1px solid $color-gray-4;
        border-radius: 0;

        &:last-of-type {
          margin-bottom: 24px;
        }

        svg {
          cursor: pointer;
          transition: all .2s ease;

          &:hover {
            color: $color-navient-dark-blue-6;
          }
        }
      }
    }

    .fileNames{
      margin-top: 8px;
      margin-bottom: 8px;
    }
    
    a{
      color: #0057B6 !important;
    }
  }

  .commentList {
    .list-group {
      .list-group-item {
        // background-color: pink;
        border-bottom: none;
        margin-bottom: 24px;
        padding: 0 !important;

        &:nth-of-type(2) {
          border-left: 1px solid $color-navient-dark-blue-6;
          padding-left: 8px !important;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        .timestamp {
          color: $color-gray-7;
          margin-top: 8px;
        }
      }
    }
  }
  
  .disputeMessages {
      margin-bottom: 16px;

      h3{
          color: $color-msb-gray-6;
      }

      span{
          color: #8C8C8C;
      }
  }
  
  .disputeMessagesAuthor {
      margin-bottom: 16px;
      border-left:2px solid $color-navient-dark-blue-6;

      h3{
          margin-left: 8px;
          color: $color-msb-gray-6;
      }
      span{
          margin-left: 8px;
          color: #8C8C8C;
      }
  }
  
  .notification_details_description {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      margin-top: 12px;
      align-items: center;

      @media (max-width: 375px) {
        grid-template-columns: 1fr;
        gap: 0;
        margin-top: 16px;
      }

      h3 {
        font-weight: $font-weight-bold;
        margin-bottom: 0;

        span {
          color: $color-gray-7;
          font-weight: $font-weight-normal;
        }
      }
  }

  .notification_details_message {
      // margin-top: 20px;
      h3 {
        font-weight: $font-weight-bold;
        margin-bottom: 8px;

        span {
          color: $color-gray-7;
          font-weight: $font-weight-normal;
        }
      }
  }

  .dispute-upload {
    margin-top: 24px;

    label {
      font-weight: 400;
    }
  }

  .dispute-comment {
    background-color: $color-gray-2;
    padding: 16px;
    border-radius: 4px;
  }