// MSB Brand Colors
$color-msb-gray-1: rgba(236,236,236,1);
$color-msb-gray-2: rgba(217,217,217,1);
$color-msb-gray-3: rgba(190,190,190,1);
$color-msb-gray-4: rgba(141,141,141,1);
$color-msb-gray-5: rgba(103,103,103,1);
$color-msb-gray-6: rgba(65,65,65,1);
$color-msb-gray-7: rgba(52,52,52,1);
$color-msb-gray-8: rgba(39,39,39,1);
$color-msb-gray-9: rgba(26,26,26,1);
$color-msb-gray-10: rgba(13,13,13,1);

$color-msb-teal-1: rgba(230,241,242,1);
$color-msb-teal-2: rgba(204,227,228,1);
$color-msb-teal-3: rgba(153,200,202,1);
$color-msb-teal-4: rgba(102,172,175,1);
$color-msb-teal-5: rgba(51,145,149,1);
$color-msb-teal-6: rgba(0,117,122,1);
$color-msb-teal-7: rgba(0,94,98,1);
$color-msb-teal-8: rgba(0,70,73,1);
$color-msb-teal-9: rgba(0,47,49,1);
$color-msb-teal-10: rgba(0,23,24,1);

$color-msb-dark-purple-1: rgba(237,231,240,1);
$color-msb-dark-purple-2: rgba(218,206,225,1);
$color-msb-dark-purple-3: rgba(181,157,195,1);
$color-msb-dark-purple-4: rgba(126,84,149,1);
$color-msb-dark-purple-5: rgba(108,59,134,1);
$color-msb-dark-purple-6: rgba(71,10,104,1);
$color-msb-dark-purple-7: rgba(57,8,83,1);
$color-msb-dark-purple-8: rgba(43,6,62,1);
$color-msb-dark-purple-9: rgba(28,4,42,1);
$color-msb-dark-purple-10: rgba(14,2,21,1);

// MSB Extended Colors
$color-msb-accent-color-msb-extended-teal-1: rgba(230,235,238,1);
$color-msb-accent-color-msb-extended-teal-2: rgba(205,216,221,1);
$color-msb-accent-color-msb-extended-teal-3: rgba(155,177,188,1);
$color-msb-accent-color-msb-extended-teal-4: rgba(105,137,154,1);
$color-msb-accent-color-msb-extended-teal-5: rgba(55,98,121,1);
$color-msb-accent-color-msb-extended-teal-6: rgba(5,59,87,1);
$color-msb-accent-color-msb-extended-teal-7: rgba(4,47,70,1);
$color-msb-accent-color-msb-extended-teal-8: rgba(3,35,52,1);
$color-msb-accent-color-msb-extended-teal-9: rgba(2,24,35,1);
$color-msb-accent-color-msb-extended-teal-10: rgba(1,12,17,1);

$color-msb-success-msb-extended-green-1: rgba(244,248,230,1);
$color-msb-success-msb-extended-green-2: rgba(232,242,204,1);
$color-msb-success-msb-extended-green-3: rgba(209,228,153,1);
$color-msb-success-msb-extended-green-4: rgba(186,215,102,1);
$color-msb-success-msb-extended-green-5: rgba(163,201,51,1);
$color-msb-success-msb-extended-green-6: rgba(140,188,0,1);
$color-msb-success-msb-extended-green-7: rgba(112,150,0,1);
$color-msb-success-msb-extended-green-8: rgba(84,113,0,1);
$color-msb-success-msb-extended-green-9: rgba(56,75,0,1);
$color-msb-success-msb-extended-green-10: rgba(28,38,0,1);

$color-msb-warning-msb-extended-yellow-1: rgba(254,247,233,1);
$color-msb-warning-msb-extended-yellow-2: rgba(253,240,211,1);
$color-msb-warning-msb-extended-yellow-3: rgba(251,224,166,1);
$color-msb-warning-msb-extended-yellow-4: rgba(250,209,122,1);
$color-msb-warning-msb-extended-yellow-5: rgba(248,193,77,1);
$color-msb-warning-msb-extended-yellow-6: rgba(246,178,33,1);
$color-msb-warning-msb-extended-yellow-7: rgba(197,142,26,1);
$color-msb-warning-msb-extended-yellow-8: rgba(148,107,20,1);
$color-msb-warning-msb-extended-yellow-9: rgba(98,71,13,1);
$color-msb-warning-msb-extended-yellow-10: rgba(49,36,7,1);

$color-msb-error-msb-extended-red-1: rgba(245,234,236,1);
$color-msb-error-msb-extended-red-2: rgba(234,213,218,1);
$color-msb-error-msb-extended-red-3: rgba(213,171,181,1);
$color-msb-error-msb-extended-red-4: rgba(192,129,143,1);
$color-msb-error-msb-extended-red-5: rgba(171,87,106,1);
$color-msb-error-msb-extended-red-6: rgba(150,45,69,1);
$color-msb-error-msb-extended-red-7: rgba(120,36,55,1);
$color-msb-error-msb-extended-red-8: rgba(90,27,41,1);
$color-msb-error-msb-extended-red-9: rgba(60,18,28,1);
$color-msb-error-msb-extended-red-10: rgba(30,9,14,1);

// Navient Brand Colors
$color-navient-brand-gray-1: rgba(109, 110, 113, 1);
$color-navient-brand-green-1: rgba(44, 153, 66, 1);
$color-navient-brand-teal-1: rgba(0, 112, 120, 1);
$color-navient-brand-purple-1: rgba(73, 14, 103, 1);

$color-navient-gray-1: rgba(236,236,236,1);
$color-navient-gray-2: rgba(217,217,217,1);
$color-navient-gray-3: rgba(190,190,190,1);
$color-navient-gray-4: rgba(141,141,141,1);
$color-navient-gray-5: rgba(103,103,103,1);
$color-navient-gray-6: rgba(65,65,65,1);
$color-navient-gray-7: rgba(52,52,52,1);
$color-navient-gray-8: rgba(39,39,39,1);
$color-navient-gray-9: rgba(26,26,26,1);
$color-navient-gray-10: rgba(13,13,13,1);

$color-navient-light-blue-1: rgba(230,247,253,1);
$color-navient-light-blue-2: rgba(204,239,252,1);
$color-navient-light-blue-3: rgba(153,223,249,1);
$color-navient-light-blue-4: rgba(102,206,245,1);
$color-navient-light-blue-5: rgba(51,190,242,1);
$color-navient-light-blue-6: rgba(0,174,239,1);
$color-navient-light-blue-7: rgba(0,139,191,1);
$color-navient-light-blue-8: rgba(0,104,143,1);
$color-navient-light-blue-9: rgba(0,70,96,1);
$color-navient-light-blue-10: rgba(0,35,48,1);

$color-navient-dark-blue-1: rgba(230,238,248,1);
$color-navient-dark-blue-2: rgba(204,221,240,1);
$color-navient-dark-blue-3: rgba(153,188,226,1);
$color-navient-dark-blue-4: rgba(102,154,211,1);
$color-navient-dark-blue-5: rgba(51,121,197,1);
$color-navient-dark-blue-6: rgba(0,87,182,1);
$color-navient-dark-blue-7: rgba(0,70,146,1);
$color-navient-dark-blue-8: rgba(0,52,109,1);
$color-navient-dark-blue-9: rgba(0,35,73,1);
$color-navient-dark-blue-10: rgba(0,17,36,1);

$color-navient-purple-1: rgba(237,231,240,1);
$color-navient-purple-2: rgba(218,206,225,1);
$color-navient-purple-3: rgba(181,157,195,1);
$color-navient-purple-4: rgba(126,84,149,1);
$color-navient-purple-5: rgba(108,59,134,1);
$color-navient-purple-6: rgba(71,10,104,1);
$color-navient-purple-7: rgba(57,8,83,1);
$color-navient-purple-8: rgba(43,6,62,1);
$color-navient-purple-9: rgba(28,4,42,1);
$color-navient-purple-10: rgba(14,2,21,1);

// Application
$color-gray-1: rgba(255,255,255,1);
$color-gray-2: rgba(250,250,250,1);
$color-gray-3: rgba(245,245,245,1);
$color-gray-4: rgba(240,240,240,1);
$color-gray-5: rgba(217,217,217,1);
$color-gray-6: rgba(191,191,191,1);
$color-gray-7: rgba(140,140,140,1);
$color-gray-8: rgba(89,89,89,1);
$color-gray-9: rgba(38,38,38,1);
$color-gray-10: rgba(0,0,0,1);

$color-daybreak-blue-blue-1: rgba(230,247,255,1);
$color-daybreak-blue-blue-2: rgba(186,231,255,1);
$color-daybreak-blue-blue-3: rgba(145,213,255,1);
$color-daybreak-blue-blue-4: rgba(105,192,255,1);
$color-daybreak-blue-blue-5: rgba(64,169,255,1);
$color-daybreak-blue-blue-6: rgba(24,144,255,1);
$color-daybreak-blue-blue-7: rgba(9,109,217,1);
$color-daybreak-blue-blue-8: rgba(0,80,179,1);
$color-daybreak-blue-blue-9: rgba(0,58,140,1);
$color-daybreak-blue-blue-10: rgba(0,39,102,1);

$color-dust-red-red-1: rgba(255,241,240,1);
$color-dust-red-red-2: rgba(255,204,199,1);
$color-dust-red-red-3: rgba(255,163,158,1);
$color-dust-red-red-4: rgba(255,120,117,1);
$color-dust-red-red-5: rgba(255,77,79,1);
$color-dust-red-red-6: rgba(245,34,45,1);
$color-dust-red-red-7: rgba(207,19,34,1);
$color-dust-red-red-8: rgba(168,7,26,1);
$color-dust-red-red-9: rgba(130,0,20,1);
$color-dust-red-red-10: rgba(92,0,17,1);

$color-volcano-volcano-1: rgba(255,242,232,1);
$color-volcano-volcano-2: rgba(255,216,191,1);
$color-volcano-volcano-3: rgba(255,187,150,1);
$color-volcano-volcano-4: rgba(255,156,110,1);
$color-volcano-volcano-5: rgba(255,122,69,1);
$color-volcano-volcano-6: rgba(250,84,28,1);
$color-volcano-volcano-7: rgba(212,56,13,1);
$color-volcano-volcano-8: rgba(173,33,2,1);
$color-volcano-volcano-9: rgba(135,20,0,1);
$color-volcano-volcano-10: rgba(97,11,0,1);

$color-sunset-orange-orange-1: rgba(255,247,230,1);
$color-sunset-orange-orange-2: rgba(255,231,186,1);
$color-sunset-orange-orange-3: rgba(255,213,145,1);
$color-sunset-orange-orange-4: rgba(255,192,105,1);
$color-sunset-orange-orange-5: rgba(255,169,64,1);
$color-sunset-orange-orange-6: rgba(250,140,22,1);
$color-sunset-orange-orange-7: rgba(212,107,8,1);
$color-sunset-orange-orange-8: rgba(173,78,0,1);
$color-sunset-orange-orange-9: rgba(135,56,0,1);
$color-sunset-orange-orange-10: rgba(97,37,0,1);

$color-calendula-gold-gold-1: rgba(255,251,230,1);
$color-calendula-gold-gold-2: rgba(255,241,184,1);
$color-calendula-gold-gold-3: rgba(255,229,143,1);
$color-calendula-gold-gold-4: rgba(255,214,102,1);
$color-calendula-gold-gold-5: rgba(255,197,61,1);
$color-calendula-gold-gold-6: rgba(250,173,20,1);
$color-calendula-gold-gold-7: rgba(212,136,6,1);
$color-calendula-gold-gold-8: rgba(173,104,0,1);
$color-calendula-gold-gold-9: rgba(135,77,0,1);
$color-calendula-gold-gold-10: rgba(97,52,0,1);

$color-sunrise-yellow-yellow-1: rgba(254,255,230,1);
$color-sunrise-yellow-yellow-2: rgba(255,251,143,1);
$color-sunrise-yellow-yellow-4: rgba(255,245,102,1);
$color-sunrise-yellow-yellow-5: rgba(255,236,61,1);
$color-sunrise-yellow-yellow-6: rgba(250,219,20,1);
$color-sunrise-yellow-yellow-7: rgba(212,177,6,1);
$color-sunrise-yellow-yellow-8: rgba(173,139,0,1);
$color-sunrise-yellow-yellow-9: rgba(135,104,0,1);
$color-sunrise-yellow-yellow-10: rgba(97,71,0,1);

$color-lime-lime-1: rgba(252,255,230,1);
$color-lime-lime-2: rgba(244,255,184,1);
$color-lime-lime-3: rgba(234,255,143,1);
$color-lime-lime-4: rgba(211,242,97,1);
$color-lime-lime-5: rgba(186,230,55,1);
$color-lime-lime-6: rgba(160,217,17,1);
$color-lime-lime-7: rgba(124,179,5,1);
$color-lime-lime-8: rgba(91,140,0,1);
$color-lime-lime-9: rgba(63,102,0,1);
$color-lime-lime-10: rgba(37,64,0,1);

$color-polar-green-green-1: rgba(246,255,237,1);
$color-polar-green-green-2: rgba(217,247,190,1);
$color-polar-green-green-3: rgba(183,235,143,1);
$color-polar-green-green-4: rgba(149,222,100,1);
$color-polar-green-green-5: rgba(115,209,61,1);
$color-polar-green-green-6: rgba(82,196,26,1);
$color-polar-green-green-7: rgba(56,158,13,1);
$color-polar-green-green-8: rgba(35,120,4,1);
$color-polar-green-green-9: rgba(19,82,0,1);
$color-polar-green-green-10: rgba(9,43,0,1);

$color-cyan-cyan-1: rgba(230,255,251,1);
$color-cyan-cyan-2: rgba(181,245,236,1);
$color-cyan-cyan-3: rgba(135,232,222,1);
$color-cyan-cyan-4: rgba(92,219,211,1);
$color-cyan-cyan-5: rgba(54,207,201,1);
$color-cyan-cyan-6: rgba(19,194,194,1);
$color-cyan-cyan-7: rgba(8,151,156,1);
$color-cyan-cyan-8: rgba(0,109,117,1);
$color-cyan-cyan-9: rgba(0,71,79,1);
$color-cyan-cyan-10: rgba(0,35,41,1);

$color-geek-blue-geekblue-1: rgba(240,245,255,1);
$color-geek-blue-geekblue-2: rgba(214,228,255,1);
$color-geek-blue-geekblue-3: rgba(173,198,255,1);
$color-geek-blue-geekblue-4: rgba(133,165,255,1);
$color-geek-blue-geekblue-5: rgba(89,126,247,1);
$color-geek-blue-geekblue-6: rgba(47,84,235,1);
$color-geek-blue-geekblue-7: rgba(29,57,196,1);
$color-geek-blue-geekblue-8: rgba(16,35,158,1);
$color-geek-blue-geekblue-9: rgba(6,17,120,1);
$color-geek-blue-geekblue-10: rgba(3,8,82,1);

$color-golden-purple-purple-1: rgba(249,240,255,1);
$color-golden-purple-purple-2: rgba(239,219,255,1);
$color-golden-purple-purple-3: rgba(211,173,247,1);
$color-golden-purple-purple-4: rgba(179,127,235,1);
$color-golden-purple-purple-5: rgba(146,84,222,1);
$color-golden-purple-purple-6: rgba(114,46,209,1);
$color-golden-purple-purple-7: rgba(83,29,171,1);
$color-golden-purple-purple-8: rgba(57,16,133,1);
$color-golden-purple-purple-9: rgba(34,7,94,1);
$color-golden-purple-purple-10: rgba(18,3,56,1);

$color-magenta-magenta-1: rgba(255,240,246,1);
$color-magenta-magenta-2: rgba(255,214,231,1);
$color-magenta-magenta-3: rgba(255,173,210,1);
$color-magenta-magenta-4: rgba(255,133,192,1);
$color-magenta-magenta-5: rgba(247,89,171,1);
$color-magenta-magenta-6: rgba(235,47,150,1);
$color-magenta-magenta-7: rgba(196,29,127,1);
$color-magenta-magenta-8: rgba(158,16,104,1);
$color-magenta-magenta-9: rgba(120,6,80,1);
$color-magenta-magenta-10: rgba(82,3,57,1);

$color-extra-background-1: rgba(240,242,245,1);
$color-extra-background-2: rgba(0,21,41,1);
$color-extra-background-3: rgba(0,12,23,1);