// Basic settings
.container-avatar {
  padding-left: 30px;

  @media (max-width: 991px) {
    margin-top: 16px;
    padding-left: 0;
  }

  .user-avatar {
    background-color: $color-gray-3;
    height: 144px;
    width: 144px;
    min-height: 144px;
    min-width: 144px;
  }

  .custom-file-label {
    display: inline-block;
    background-color: $white;
    margin-top: 12px;

    svg {
      margin-right: 8px;
    }
  }
}

// Notification settings
.form-notifications {
  th,
  td {
    text-align: center;
    padding: 12px 0 !important;
    width: 100px;
    min-width: 100px;
    border-left: 1px solid $color-gray-4;

    &:first-of-type {
      text-align: left;
      width: auto;
      min-width: 250px;
      border-left: none;
      padding-right: 12px !important;
      padding-left: 12px !important;
    }
  }

  tr {
    td {
      border-bottom: 1px solid $color-gray-4;
    }

    &:first-of-type {
      td {
        border-bottom: none;
      }
    }
  }


  thead {
    th {
      background-color: transparent;
    }
  }

  .notification-header {
    h3 {
      font-weight: $font-weight-bold;
      margin-bottom: 0;
    }
  }

  .notification-priority-delivery {
    font-size: 0.875rem;

    .ico-critical {
      color: $color-dust-red-red-6;
      margin-right: 6px;
    }

    .ico-urgent {
      color: $color-msb-warning-msb-extended-yellow-6;
      margin-right: 6px;
    }

    .ico-notice {
      color: $color-polar-green-green-6;
      margin-right: 6px;
    }
  }

  .notification-type-setting {
    h3 {
      margin-bottom: 4px;
    }
    
    p {
      color: $color-gray-7;
      margin-bottom: 0;
    }

    .form-check .form-check-input {
      float: none;
    }

    .toggle-switch {
      justify-content: center;
    }
  }
}