.userRoleGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  row-gap: 8px;
  margin-top: 24px;
  
  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
    // row-gap: 0;
  }

  @media only screen and (min-width: 426px) and (max-width: 995px) {
    grid-template-columns: repeat(2, 1fr);
    // row-gap: 0;
  }

  .toggle-switch {
    margin-bottom: 0;
  }
}

.userRoleName {
  padding: 8px;
}

.navientUserSubmit {
  margin-top: 24px;
}

.navientUserFooterCancel {
  float: right;
}

.userAvailableClients {
  margin-left: 16px
}

.internalUserDialog {
  width: 100%;
  margin: 0
}


.clientUserDialogButtons {
  float: right;
  margin-top: 8px;
}

.clientUserDeleteConfirm {
  float: right;
  margin-left: 4px;
}

.clientUserSubmit {
  margin-top: 24px;
}

.clientUserFooterCancel {
  float: right;
}

.roleTabContainier {
  display: flex;
}

.scrollbarContainer {
  display: flex;
  padding: 0 24px;
  overflow: auto;
  width: 100%;
}

.roleTabWidth {
  width: max-content;
}

@media only screen and (min-width: 500px) {
  .scrollbarContainer {
    overflow: hidden;
  }
}