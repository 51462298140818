.react-page-editable {
  background-color: $color-gray-4;
  
  .react-page-cell-insert-new {
    border: 1px dashed $color-gray-5 !important;
    margin: 24px auto 0 !important;
    border-radius: 4px;
    width: 100% !important;

    &:after {
      content: '+ Add widget' !important;
      font-family: $font-family-sans-serif !important;
      font-size: 14px !important;
      line-height: 1 !important;
      padding: 10px 12px;
      width: auto !important;
      border-radius: 4px !important;
      transition: all 0.2s ease;
    }

    &:hover,
    &.hover {
      background: transparent !important;
      border: 1px dashed $color-navient-dark-blue-6 !important;

      &:after {
        background-color: $color-msb-gray-2;
      }
    }
  }

  &.react-page-editable-mode-edit .react-page-cell-handle,
  &.react-page-editable-mode-resizing .react-page-cell-handle,
  &.react-page-editable-mode-layout .react-page-cell-handle {
    border-radius: 4px 4px 0 0 !important;
    box-shadow: 0px -6px 6px rgba(31, 41, 55, 0.05),
    0px -15px 15px rgba(31, 41, 55, 0.1) !important;
  }

  &.react-page-editable-mode-edit .react-page-cell.react-page-cell-has-plugin:hover,
  &.react-page-editable-mode-resizing .react-page-cell.react-page-cell-has-plugin:hover,
  &.react-page-editable-mode-layout .react-page-cell.react-page-cell-has-plugin:hover {
    box-shadow: 0px 0px 6px rgba(31, 41, 55, 0.05),
    0px 10px 15px rgba(31, 41, 55, 0.1) !important;
  }
}

.react-page-controls-mode-toggle-control-group {
  .react-page-controls-mode-toggle-button-inner {
    button {
      // color: $color-msb-gray-6;
      border-radius: 4px;
    }
  }
}

.react-page-controls-mode-toggle-button-description {
  font-family: $font-family-sans-serif !important;
  font-size: 14px !important;
  border-radius: 4px;
}

// Slate Bold, Italic, Underline mini toolbar
.react-page-plugins-content-slate-inline-toolbar { 
  button {
    color: $color-msb-gray-6 !important
  }

  &.react-page-plugins-content-slate-inline-toolbar--hidden {
   button {
     color: $color-msb-gray-6 !important
   }
 }
}

.react-page-row-droppable-container {

  // Slate Editor
  div[data-slate-editor] {
    h1 {
      font-size: 56px;
      line-height: 64px;
    }
  
    h2 {
      font-size: 46px;
      line-height: 54px;
    }
  
    h3 {
      font-size: 38px;
      line-height: 46px;
    }
  
    h4 {
      font-size: 30px;
      line-height: 38px;
    }
  
    h5 {
      font-size: 24px;
      line-height: 32px;
    }
  
    h6 {
      font-size: 20px;
      line-height: 28px;
    }
  
    ul, ol {
      li {
        h1 {
          font-size: 56px;
          line-height: 64px;
        }
  
        h2 {
          font-size: 46px;
          line-height: 54px;
        }
  
        h3 {
          font-size: 38px;
          line-height: 46px;
        }
  
        h4 {
          font-size: 30px;
          line-height: 38px;
        }
  
        h5 {
          font-size: 24px;
          line-height: 32px;
        }
  
        h6 {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
  
  p {
    a {
      text-decoration: none;
      
      &:hover,
      &:active {
        color: $color-navient-dark-blue-5;
      }
    }
  }

  b,
  strong {
    line-height: 1.375rem;
  }
}

.clientMetadataDetails {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    margin-top: 12px !important;
    gap: 8px;

    @media (max-width: 984px) {
        grid-template-columns: 1fr !important;
    }

    @media (max-width: 374px) {
        gap: 0;
        margin-bottom: 8px;
    }
    h3 {
        font-weight: $font-weight-bold;
        margin-bottom: 0;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    span {
        justify-content: flex-start !important;
    }
}