.agreement-section-header {
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .agreement-body {
    margin-right: 30px;

    @media (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  .agreement-export {
    margin-left: 30px;

    .export-agreement-button {
      svg {
        margin-right: 8px;
      }
    }

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
}

.agreement-form {
  .card {
    border: 1px solid $color-gray-5;
    border-radius: .25rem;
    box-shadow: none;
    overflow: hidden;

    .card-header {
      background: $color-gray-3 !important;
      height: auto;
      margin-bottom: -1px;
      padding: 12px 16px;
      border-radius: 0;
      cursor: pointer;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;

      svg {
        margin-right: 16px;
      }
    }

    .card-body {
      // border-bottom: 1px solid $color-gray-5;
      padding: 16px;

      p {
        color: $color-gray-7;
        margin-bottom: 32px;
      }

      hr {
        background-color: $color-gray-4;
        margin: 19px 0;
        opacity: 1;
      }

      .list-group-item {
        padding: 0;
      }

      .agreement-department-list {
        .list-group-item {
          border-bottom: 1px solid $color-gray-4;
          margin-bottom: 19px;
          padding-bottom: 19px;

          &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
      }

      .agreement_input {
        height: 40px;
      }

      .agreement_details_row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        align-items: center;
        margin-bottom: 8px;

        &:last-of-type {
          margin-bottom: 0;
        }

        h3 {
          font-weight: $font-weight-bold;
          margin-bottom: 0;

          span {
            color: $color-gray-7;
            font-weight: $font-weight-normal;
          }
        }

        .vendorAddress,
        .vendorContact {
          span {
            color: $color-gray-7;
            margin-left: 4px;
          }

          .row {
            margin-bottom: 0;

            .form-group {
              margin-bottom: 16px;
            }

            &:last-of-type {
              .form-group {
                margin-bottom: 0;
              }
            }
          }
        }

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 0;
          margin-bottom: 16px;

          h3 {
            margin-bottom: 4px;
          }
        }
      }
    }

    .col-form-label {
      font-weight: $font-weight-normal;
      padding-top: 0;
      padding-bottom: 0;
    }

    &:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}